import React from "react";
import styled from "@emotion/styled";

const ColorBar = styled.div`
  background-color: ${({ color }) => color};
  height: 100%;
  flex: 1;
`;

const Wrap = styled.div`
  display: flex;
`;

export default function OcmaColorsBar({ ...props }) {
  return (
    <Wrap {...props}>
      <ColorBar color="#d0202e" />
      <ColorBar color="#ece343" />
      <ColorBar color="#6dbd45" />
      <ColorBar color="#131f46" />
    </Wrap>
  );
}
