import React from "react";
import { css } from "@emotion/core";
import styles from "../constants/theme";

export default function ContentSection({ ...rest }) {
  return (
    <div
      css={css`
        margin-bottom: ${styles.margin.large};
        width: 100%;
      `}
      {...rest}
    />
  );
}
