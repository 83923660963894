import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import styles from "../constants/theme";

import OcmaColorsBar from "./OcmaColorsBar";

const gradientHeight = `6px`;

const MainContentWrap = styled.div`
  background-color: ${props => props.bg || styles.colors.white};
  border-radius: ${styles.borderRadius.big};
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  position: relative;
  /* margin-top: -180px; */
  padding-top: ${styles.margin.mid};
  padding-bottom: ${gradientHeight};

  ${styles.mq[1]} {
    /* margin-top: -32px; */
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${props => props.headingColor};
  }
`;

const colorBarStyle = css`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: ${gradientHeight};
`;

export default function({ className, children, ...rest }) {
  return (
    <MainContentWrap className={`container ${className}`} {...rest}>
      {children}
      <OcmaColorsBar css={colorBarStyle} />
    </MainContentWrap>
  );
}
