import React from "react";
import { css } from "@emotion/core";
import styles from "../constants/theme";
import BackgroundImage from "gatsby-background-image";

export default function Hero({ imageURL, children, ...rest }) {
  return (
    <BackgroundImage
      fluid={imageURL}
      css={css`
        height: calc(100vh - ${styles.nav.height});
        background-size: cover;
        background-position: center bottom;
        background-repeat: no-repeat;
        background-color: ${styles.colors.blueDark};
        position: absolute !important;
        width: 100vw;
      `}
      {...rest}
    >
      {/* <div
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.1));
        `}
      > */}
      {children}
      {/* </div> */}
    </BackgroundImage>
  );
}
