import React from "react";
import styled from "@emotion/styled";
import styles from "../constants/theme";
import Link from "../components/Link";

const Component = styled(Link)`
  color: ${styles.colors.red};
  text-transform: uppercase;
  font-size: ${styles.text.pFeat};
  font-weight: bold;

  &:hover {
    color: ${styles.colors.red};
  }
`;

export default function FeatLink({ ...rest }) {
  return <Component {...rest} />;
}
